<template>
  <section class="financial-recap" :class="{ 'ds-color-white': theme === 'dark' }">
    <h3 v-if="title" class="ds-heading-small test-class">
      {{ title }}
    </h3>
    <slot />
    <div class="financial-recap__eyebrow ds-margin-top-24 ds-text-small ds-text-transform-uppercase">
      <p class="ds-font-weight-bold" data-j-period>
        {{ period }}
      </p>
      <DsIcon v-if="arrow === 'up'" category="arrows" name="external-link-small"
        class="ds-margin-left-8 ds-color-green" />
      <DsIcon v-else category="arrows" name="arrow-diagonal-down-small"
        class="financial-recap__eyebrow__icon ds-margin-left-8 ds-color-grey-450" />
      <p class="ds-color-grey-450 ds-margin-left-8 ds-font-weight-bold financial-recap-number">
        {{ labels.YOY }}
        <span ref="yoy" style="min-width: 100px">
          {{ computedYOYStats }} </span>%
      </p>
      <p v-if="margin" class="ds-color-grey-450 ds-margin-left-8 ds-font-weight-bold">
        {{ labels.margin }}
        <span ref="margin" style="min-width: 100px">
          {{ computedMarginStats }} </span>%
      </p>
    </div>
    <h4 class="ds-heading-large ds-margin-top-4" :class="withHeadlineGrey ? 'ds-color-grey-450' : 'ds-color-green'">
      €
      <span ref="recap" style="min-width: 100px">{{ computedHeadline }}</span>
      {{ computedAmount }}
    </h4>
  </section>
</template>

<script setup>
import { DsIcon } from "awds";
const { theme } = useTheme();
const { $gsap } = useNuxtApp();

const id = useId();

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  period: {
    type: String,
    required: true,
  },
  yoy: {
    type: Number,
    required: true,
  },
  margin: {
    type: Number,
    default: null,
  },
  headline: {
    type: Number,
    required: true,
  },
  withHeadlineGrey: {
    type: Boolean,
    default: false,
  },
  arrow: {
    type: String,
    required: true,
    validator: (value) => ["up", "down"].includes(value),
  },
  isBillion: {
    type: Boolean,
    default: false,
  },
  isAnimated: {
    type: Boolean,
    default: true,
  },
});

const yoyDisplayValue = ref(0);
const marginDisplayValue = ref(0);
const headlineDisplayValue = ref(0)
const recap = ref();
const yoy = ref();
const margin = ref();

const labels = computed(() => {
  return {
    YOY: "YOY",
    margin: "Margin",
    billion: "BN",
    million: "MN",
  };
});

const computedAmount = computed(() => {
  return props.isBillion ? labels.value.billion : labels.value.million;
});

const computedHeadline = computed(() => {
  return props.isAnimated ? headlineDisplayValue.value  : props.headline?.toFixed(1);
});

const computedYOYStats = computed(() => {
  return props.isAnimated ? yoyDisplayValue.value : props.yoy?.toFixed(0);
});

const computedMarginStats = computed(() => {
  return props.margin
      ? props.isAnimated
          ? marginDisplayValue.value
          : props.margin?.toFixed(0)
      : null;
});

const initAnimation = () => {
  if (!props.isAnimated) {
    return;
  }

  if (!recap.value) {
    return;
  }
  const timeline = $gsap.timeline();
  timeline.to(
    recap.value,
    {
      delay: 0.5,
      innerText: props.headline.toFixed(1),
      duration: 1,
      snap: {
        innerText: 0.1,
      },
      ease: "power4.inOut",
    },
    "start"
  );
  timeline.to(
    yoy.value,
    {
      delay: 0.5,
      innerText: props.yoy?.toFixed(0),
      duration: 1,
      snap: {
        innerText: 0.1,
      },
      ease: "power4.inOut",
    },
    "start"
  );

  if (props.margin) {
    timeline.to(
      margin.value,
      {
        delay: 0.5,
        innerText: props.margin?.toFixed(0),
        duration: 1,
        snap: {
          innerText: 0.1,
        },
        ease: "power4.inOut",
      },
      "start"
    );
  }
}

onMounted(async () => {
  await nextTick();
 initAnimation()
});

watch(() => props.isAnimated, initAnimation)

</script>

<style lang="scss" scoped>
@import "awds/foundation-vars.scss";

.financial-recap {
  &__eyebrow {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &__icon {
      transform: rotate(270deg);
    }
  }
}
</style>
